<template>
  <div class="retrieve">
    <div class="form">
      <el-form :model="formData"
               ref="formData"
               :rules="rules"
               @submit.native.prevent
               hide-required-asterisk
               status-icon
               inline
               v-loading="loading"
               element-loading-text="正在提交"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(F, F, F, 0.01)">
        <el-form-item v-if="infoShow" label="姓 名" prop="name" key="name">
          <el-input v-model="formData.name" readonly clearable></el-input>
        </el-form-item>
        <el-form-item label="号 码" prop="phone" key="phone">
          <el-input v-model="formData.phone"
                    placeholder="请输入手机号码"
                    clearable
                    @keyup.enter.native="submit"></el-input>
        </el-form-item>
        <el-form-item v-if="infoShow" label="类 型" prop="type">
          <el-input v-model="formData.type" readonly clearable></el-input>
        </el-form-item>
        <el-form-item v-if="infoShow" label="余 额" prop="balance">
          <el-input v-model="formData.balance" readonly clearable></el-input>
        </el-form-item>
        <el-form-item v-if="infoShow" label="备 注" prop="remark">
          <el-input v-model="formData.remark" readonly clearable></el-input>
        </el-form-item>
        <el-form-item v-if="infoShow" label="上 次" prop="lastConsume">
          <el-input v-model="formData.lastConsume" readonly clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">查 询</el-button>
          <el-button v-if="infoShow" type="success" @click="consume">消 费</el-button>
          <el-button v-if="infoShow" type="warning" @click="addMoney">充 值</el-button>
          <el-button v-if="infoShow" type="info" @click="record">记 录</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="dialog_record">
      <el-dialog title="会员卡统计" width="40%" :visible.sync="allCardInfo">
        <el-table :data="gridData">
          <el-table-column property="modifyTime" align="center" label="时 间" width="200"></el-table-column>
          <el-table-column property="type" align="center" label="类 型" width="100"></el-table-column>
          <el-table-column property="amount" align="center" label="变 更" width="100"></el-table-column>
          <el-table-column property="balance" align="center" label="变更后" width="100"></el-table-column>
        </el-table>
      </el-dialog>
    </div>
    <div class="web">
      <a class="text" href="https://beian.miit.gov.cn/" target="_blank">皖ICP备2022007794号</a>
    </div>
  </div>
</template>

<script>
  import {get, post} from "../utils/request";
  import dayjs from 'dayjs'

  export default {
    name: 'Add',
    data() {
      let phoneValidator = (rule, value, callback) => {
        const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/;
        if (!reg.test(value)) {
          return callback(new Error("请输入正确的电话号码"));
        } else {
          this.check = true;
          callback();
        }
        // }
      }
      return {
        // 每查询一个号码后【消费】的次数
        consumeCount: 0,
        // 每查询一个号码后【充值】的次数
        continueCount: 0,
        // 查询号码后其他信息的显示
        infoShow: false,
        // 号码表单验证
        check: false,
        // 控制加载图标显示
        loading: false,
        // 控制消费记录遮罩层显示
        allCardInfo: false,
        // 消费记录数据
        gridData: [],
        // 表单数据
        formData: {
          name: '',
          phone: '',
          type: '',
          balance: '',
          remark: '',
          lastConsume: ''
        },
        // 表单验证规则
        rules: {
          phone: [{validator: phoneValidator, trigger: 'blur'}]
        },

      }
    },
    methods: {
      // 电话号码验证
      phoneVerify() {
        if (!/^1[3|4|5|6|7|8][0-9]\d{8}$/.test(this.formData.phone)) {
          this.$message.error('请输入正确的电话号码');
          return false;
        } else {
          this.check = true;
          return true;
        }
      },
      //【查询】按钮
      async submit() {
        if (!this.check) {
          return
        }
        this.loading = true;
        await post('/api/searchByPhone', this.formData.phone).then(res => {
          if (res.code == '0') {
            res.data.lastConsume = dayjs(res.data.lastConsume).format("YYYY-MM-DD HH:mm:ss");
            this.formData = res.data;
            this.loading = false;
            this.infoShow = true;
            // this.consumeCount = 0;
          } else {
            this.$message.error(res.msg)
            this.loading = false;
            // this.consumeCount = 0;
          }
        });
        this.check = false;
      },
      // 【消费】按钮
      consume() {
        if (this.phoneVerify()) {
          if (this.consumeCount > 0) {
            this.$message({
              type: 'error',
              duration: '5000',
              message: '请确认当前会员卡信息是不是上一客户的信息'
            });
          }
            if (this.formData.type == '300元染烫8折卡'
                || this.formData.type == '500元染烫7折卡'
                || this.formData.type == '1000元染烫5折卡') {
              this.$prompt('请输入消费金额', '消费', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^(([1-9]\d*)|0)(\.\d{1,2})?$/,
                inputErrorMessage: '消费金额输入不正确'
              }).then(({value}) => {
                this.consumeMethod(value);
                this.consumeCount++;
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '取消输入'
                });
              });
            } else {
              this.$prompt('请输入消费次数', '消费', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: 1,
                inputPattern: /^[0-9]*[1-9][0-9]*$/,
                inputErrorMessage: '消费次数输入不正确'
              }).then(({value}) => {
                this.consumeMethod(value);
                this.consumeCount++;
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '取消输入'
                });
              });
            }
        }
      },
      // 消费接口
      async consumeMethod(value) {
        await post('/api/consume', {amount: value, phone: this.formData.phone})
            .then(res => {
          if (res.code == '0') {
            this.$message({
              type: "success",
              message: res.msg
            });
          } else {
            this.$message.error(res.msg);
          }
          this.$nextTick(() => {
            this.submit();
          });
        })
      },
      // 【充值】按钮
      addMoney() {
          if (this.phoneVerify()) {
          if (this.continueCount > 0) {
            this.$message({
              type: 'error',
              duration: '5000',
              message: '请确认当前会员卡信息是不是上一客户的信息'
            });
          }
            if (this.formData.type == '300染烫8折卡'
                || this.formData.type == '500元染烫7折卡'
                || this.formData.type == '1000元染烫5折卡') {
              this.$prompt('请输入充值金额', '充值', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^(([1-9]\d*)|0)(\.\d{1,2})?$/,
                inputErrorMessage: '充值金额输入不正确'
              }).then(({value}) => {
                this.addMoneyMethod(value);
                this.continueCount++;
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '取消输入'
                });
              });
            } else {
              this.$prompt('请输入充值次数', '消费', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: 1,
                inputPattern: /^[0-9]*[1-9][0-9]*$/,
                inputErrorMessage: '充值次数输入不正确'
              }).then(({value}) => {
                this.addMoneyMethod(value);
                this.continueCount++;
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '取消输入'
                });
              });
            }
        }
      },
      // 充值接口
      async addMoneyMethod(value) {
        await post('/api/add_money', {amount: value, phone: this.formData.phone})
            .then(res => {
              if (res.code == '0') {
                this.$message({
                  type: "success",
                  message: res.msg
                });
              } else {
                this.$message.error(res.msg);
              }
              this.$nextTick(() => {
                this.submit();
              });
            })
      },

  // 【记录】按钮
      async record() {
          this.loading = true;
          await post('/api/retrieveOrder', this.formData.phone).then(res => {
            res.data.forEach((order, index, arr) => {
              order.modifyTime = dayjs(order.modifyTime).format("YYYY-MM-DD HH:mm:ss");
              if (order.type == '充值') {
                order.amount = '+' + order.amount;
              } else {
                order.amount = '-' + order.amount;
              }
            })
            this.gridData = res.data;
          })
            this.loading = false;
            this.allCardInfo = true;
      },
    }
  }
</script>

<style lang="less">
  .retrieve {

    .form {
      width: 30%;
      margin: auto;
    }

    .web {
      width: 100%;
      position: fixed;
      z-index: 1000;
      bottom: 0;
      left: 0;
      height: 39px;
      padding-top: 1px;
      overflow: hidden;
      zoom: 1;
      margin: 0;
      background: #fff;
      .text {
      color: #bbb;
      font-size: 12px;
      text-decoration: none;
      text-align: center;
      line-height: 39px;
      font-family: Arial, sans-serif;
    }
    }

  }
</style>